
import { Component, Vue, Ref } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";
import axios from "axios";
@Ref("form")
@Component
export default class LoginView extends Vue {
  email = "";
  password = "";
  passwordError = "";
  emailRules = [
    (v: string) => !!v || this.$t("email_required"),
    (v: string) => /.+@.+/.test(v) || this.$t("email_invalid"),
  ];
  showLogin = false;
  passwordRules = [
    (v: string) => !!v || this.$t("password_required"),
    (v: string) => v.length >= 8 || this.$t("password_min_length"),
  ];
  @Ref("form")
  private form!: HTMLFormElement;
  login_submit() {
    if (!this.form.validate()) {
      return;
    }
    this.$store
      .dispatch("ex_login", {
        user_email: this.email,
        password: this.password,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.$router.push("/");
        } else if (res.code === 40001) {
          this.passwordError = this.$t("password_error").toString();
        } else {
          this.passwordError = res.data.msg;
        }
      })
      .catch((err: any) => {
        this.passwordError = err.response.data.msg;
      });
  }
}
