
import { Component, Vue, Ref } from "vue-property-decorator";
import axios from "axios";

@Component
export default class ChangePasswordView extends Vue {
  passwordForm: any = {
    token: "",
    new_password: "",
    new_password_confirm: "",
  };

  changePasswordError = "";
  passwordChanged = false;
  newPasswordRules = [
    (v: string) => !!v || this.$t("new_password_required"),
    (v: string) => v.length >= 8 || this.$t("password_min_length"),
  ];

  newPasswordConfirmRules = [
    (v: string) => !!v || this.$t("confirm_new_password_required"),
    (v: string) =>
      v === this.passwordForm.new_password ||
      this.$t("confirm_password_not_match"),
  ];

  @Ref("form")
  private form!: HTMLFormElement;

  created() {
    this.passwordForm.token = this.$route.params.token as string; // Type-casting as string
    console.log("Token from URL:", this.passwordForm.token);

    // You can now use the token for any purpose, e.g., API calls
  }
  change_password_submit() {
    if (!this.form.validate()) {
      return;
    }
    this.$store
      .dispatch("ex_changepassword", this.passwordForm)
      .then((res: any) => {
        console.log(res);
        if (res.code === 200) {
          this.passwordChanged = true;
        } else {
          this.changePasswordError = res.msg;
        }
      })
      .catch((err: any) => {
        //this.registerError = err.response.msg;
      });
    // Implement the change password logic here.
    // Call the API endpoint to change the password and handle the response.
    // For now, I'll just log it to the console.
    console.log("Change password submitted!");
  }
  returnToLogin() {
    this.$router.push("/login");
  }
}
