
import Vue from "vue";
import User from "./components/User.vue";

export default Vue.extend({
  name: "App",
  components: { User },
  data: () => ({
    dialog: false,
    set_lange: "English",
    states: ["简体中文", "English"],
    tags: [""],
    isSmallScreen: false, // New data for checking screen size
  }),
  computed: {
    dark: {
      get() {
        return this.$vuetify.theme.dark;
      },
      set(value) {
        this.$vuetify.theme.dark = value;
        localStorage.setItem("darkMode", value);
      },
    },
  },
  methods: {
    wiki() {
      //打开新窗口
      window.open("https://doc.kkgkd.com/");
    },
    about() {
      this.$router.push("/about");
    },
    chang_lange() {
      this.dialog = false;
      switch (this.set_lange) {
        case "English":
          this.$i18n.locale = "en";
          break;
        case "简体中文":
          this.$i18n.locale = "cn";
      }
    },
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 730;
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);

    if (localStorage.set_lange) {
      this.set_lange = localStorage.set_lange;
      this.chang_lange();
    }

    const darkMode = localStorage.getItem("darkMode");
    if (darkMode !== null) {
      this.$vuetify.theme.dark = JSON.parse(darkMode);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  watch: {
    set_lange(newName) {
      localStorage.setItem("set_lange", newName);
    },
  },
});
