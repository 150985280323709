
import { Component, Vue, Watch } from "vue-property-decorator";

interface PluginData {
  Version: number;
  Data: any; // 你可能需要根据实际数据结构进一步指定这里的类型
}

interface ResolveInfo {
  GUID: string;
  Slot: number;
  LocalSlot: number;
  Property: string;
  CategoryNo: number;
}

interface PluginDataEx {
  Version: number;
  Name: string;
  RequiredPluginGUIDs: string[];
  RequiredZipmodGUIDs: ResolveInfo[];
}

interface ChaFileParameterEx {
  version: string;
  lastname: string;
  firstname: string;
  nickname: string;
  sex: number;
}

interface Card {
  extended_list: { [key: string]: PluginDataEx };
  char_info: ChaFileParameterEx;
  card_type: string;
  load_version: string;
  path: string;
  // 这里我省略了Extended、Image1和Image2，因为在你的JSON标记中，它们被设置为"-"，表示不会序列化。如果你在TS中需要它们，可以添加回来。
}

interface Response<T> {
  Code: number;
  Msg: string;
  Data: T;
}

type UploadInfoTemp = Card;
@Component
export default class CardUploadView extends Vue {
  file: File = new File([], "");
  auto_fill = false;
  title = "";
  plugin_list: string[] = [];
  zipmod_list: Map<string, any> = new Map();
  about = "";
  extendedListLength = 0;
  nonce = 0;
  file_name_ex = "";
  CardExist = false;
  original = false;
  select = null;
  loading = false;
  search = null;
  comment_ban = false;
  dialog = false;
  UploadInfoTemp: UploadInfoTemp = {
    extended_list: {},
    char_info: {
      version: "",
      lastname: "",
      firstname: "",
      nickname: "",
      sex: 0,
    },
    card_type: "",
    load_version: "",
    path: "",
  };
  selectedImage: string | ArrayBuffer | null = null;
  colors = ["green", "purple", "indigo", "cyan", "teal", "orange"];
  chips: string[] = [];
  items = [];
  tags: Map<string, any> = new Map();
  MainRule = [(v: string) => !!v || "This field is required"];

  mounted() {
    this.$store
      .dispatch("ex_me")
      .then((res: any) => {
        // eslint-disable-next-line no-empty
        if (res.code === 0) {
        } else {
          this.$router.push("/login");
        }
        console.log(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  // 文件选择后执行的操作
  onFileSelected() {
    this.selectedImage = null;
    this.CardExist = false;
    this.file_name_ex = "";
    if (this.file) {
      const formData = new FormData();
      formData.append("card", this.file);
      this.$store
        .dispatch("upload_png", formData)
        .then((res: any) => {
          console.log(res);
          if (res.code === 0) {
            this.file_name_ex = "(已上传至缓存,等待数据提交)";
            this.UploadInfoTemp = JSON.parse(res.data);
            Object.keys(this.UploadInfoTemp.extended_list).forEach(
              (item: any) => {
                console.log(item);
                this.plugin_list.push(item);
              }
            );
            this.UploadInfoTemp.extended_list[
              "com.bepis.sideloader.universalautoresolver"
            ].RequiredZipmodGUIDs.forEach((item: any) => {
              console.log(item);
              this.zipmod_list.set(item.GUID, item);
            });
            this.autoFill();
          } else if (res.code === 56666) {
            this.file_name_ex = "(已在缓存找到,等待数据提交)";
            this.UploadInfoTemp = JSON.parse(res.data);
            Object.keys(this.UploadInfoTemp.extended_list).forEach(
              (item: any) => {
                console.log(item);
                this.plugin_list.push(item);
              }
            );
            this.UploadInfoTemp.extended_list[
              "com.bepis.sideloader.universalautoresolver"
            ].RequiredZipmodGUIDs.forEach((item: any) => {
              console.log(item);
              this.zipmod_list.set(item.GUID, item);
            });
            this.autoFill();
          } else if (res.code === 401) {
            //未登录
            this.$router.push("/login");
          } else {
            this.CardExist = true;
            this.UploadInfoTemp = res;
            this.file_name_ex = "(" + res.msg + ")";
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    this.selectedImage = URL.createObjectURL(this.file);
  }

  clear() {
    this.file = new File([], "");
    this.auto_fill = false;
    this.title = "";
    this.plugin_list = [];
    this.zipmod_list = new Map();
    this.about = "";
    this.extendedListLength = 0;
    this.nonce = 0;
    this.file_name_ex = "";
    this.CardExist = false;
    this.dialog = false;
    this.UploadInfoTemp = {
      extended_list: {},
      char_info: {
        version: "",
        lastname: "",
        firstname: "",
        nickname: "",
        sex: 0,
      },
      card_type: "",
      load_version: "",
      path: "",
    };
    this.selectedImage = null;
    this.chips = [];
  }

  autoFill() {
    if (this.auto_fill && this.UploadInfoTemp) {
      this.title =
        this.UploadInfoTemp.char_info.firstname +
        this.UploadInfoTemp.char_info.lastname;
    } else {
      this.title = "";
    }
  }

  remove(item: string) {
    this.chips.splice(this.chips.indexOf(item), 1);
  }
  get dark() {
    return this.$vuetify.theme.dark;
  }
  submit() {
    //遍历chips从tags中获取对应的tag_id并组成数组发
    //创建int 数组
    let tagsint: any[] = [];
    this.chips.forEach((item) => {
      tagsint.push(item);
    });
    this.$store
      .dispatch("upload_png_info", {
        md5: this.UploadInfoTemp.path,
        cardtage: tagsint,
        commentban: this.comment_ban,
        title: this.title,
        note: this.about,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.dialog = true;
        } else {
          this.dialog = false;
        }
        console.log(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  @Watch("search")
  onSearchChange(newVal: string, oldVal: string) {
    newVal && newVal !== this.select && this.querySelections(newVal);
  }

  querySelections(v: string) {
    this.loading = true;
    const options = {
      params: { q: v },
    };
    this.$store
      .dispatch("ex_search_tags", options)
      .then((res: any) => {
        if (res.code === 0 && Array.isArray(res.data)) {
          // Extract the 'tt' values from each object in the data array
          this.items = res.data.map((item: { tt: string }) => {
            this.tags.set(item.tt, item);
            return item.tt;
          });
        }
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
        this.loading = false; // Ensure loading is set to false even on error
      });
    // axios
    //   .request(options)
    //   .then((response) => {
    //     if (response.data.code === 0 && Array.isArray(response.data.data)) {
    //       // Extract the 'tt' values from each object in the data array
    //       this.items = response.data.data.map((item: { tt: string }) => {
    //         this.tags.set(item.tt, item);
    //         return item.tt;
    //       });
    //     }
    //     this.loading = false;
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     this.loading = false; // Ensure loading is set to false even on error
    //   });
  }
}
