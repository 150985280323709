
import { Component, Vue, Ref } from "vue-property-decorator";
import axios from "axios";
import request from "@/uitls/request";
@Ref("form")
@Component
export default class RegisterView extends Vue {
  regform: any = {
    nickname: "",
    user_email: "",
    password: "",
    password_confirm: "",
  };

  registerError = "";
  nicknameRules = [
    (v: string) => !!v || this.$t("nickname_required"),
    (v: string) => v.length >= 2 || this.$t("nickname_min_length"),
  ];
  regEmailRules = [
    (v: string) => !!v || this.$t("email_required"),
    (v: string) => /.+@.+/.test(v) || this.$t("email_invalid"),
  ];

  regPasswordRules = [
    (v: string) => !!v || this.$t("password_required"),
    (v: string) => v.length >= 8 || this.$t("password_min_length"),
  ];

  regConfirmPasswordRules = [
    (v: string) => !!v || this.$t("confirm_password_required"),
    (v: string) =>
      v === this.regform.password || this.$t("confirm_password_not_match"),
  ];
  @Ref("form")
  private form!: HTMLFormElement;
  register_submit() {
    if (!this.form.validate()) {
      return;
    }
    this.$store
      .dispatch("ex_register", this.regform)
      .then((res: any) => {
        console.log(res);
        if (res.code === 0) {
          this.$router.push("/login");
        } else {
          this.registerError = res.msg;
        }
      })
      .catch((err: any) => {
        this.registerError = err.response.msg;
      });
  }
}
