
import { Component, Vue, Ref } from "vue-property-decorator";

@Component
export default class ForgotPasswordView extends Vue {
  resetForm: any = {
    user_email: "",
  };
  loading = false;
  resetError = "";
  resetSuccess = false;

  resetEmailRules = [
    (v: string) => !!v || this.$t("email_required"),
    (v: string) => /.+@.+/.test(v) || this.$t("email_invalid"),
  ];

  @Ref("form")
  private form!: HTMLFormElement;

  // New method to handle Return to Login button click
  returnToLogin() {
    this.$router.push("/login");
  }

  resetPassword() {
    if (!this.form.validate()) {
      return;
    }
    this.loading = true;
    this.$store
      .dispatch("ex_forget", this.resetForm)
      .then((res: any) => {
        if (res.code === 200) {
          this.resetSuccess = true;
        } else {
          this.resetError = res.msg;
        }
      })
      .catch((err: any) => {
        this.resetError = err.response
          ? err.response.msg
          : "An error occurred.";
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
